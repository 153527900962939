import React, { Component } from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import './style.css';
import Error404 from './components/Error404';
import ProjectDetail from './components/ProjectDetail'
import Home from './components/Home'

class App extends Component {
  render() {
    return (
      <div className="App">
        <BrowserRouter>
        <Switch>   
          <Route path="/" component={Home} exact /> 
          <Route path="/ProjectDetail" component={ProjectDetail} />
          <Route component={Error404} />
        </Switch>
        </BrowserRouter>
      </div>
    )
  }
}

export default App;
