import React from 'react'

class Footer extends React.Component {
    state = {  }
    render() { 
        return ( <footer className="footer">
        <div className="container">
          <ul className="list-unstyled footerLink">
            <li><a href="index.html">work</a></li>
            <li><a href="about.html">about</a></li>
            <li><a href="blog.html">blog</a></li>
            <li><a href="contact.html">contact</a></li>
          </ul>
          <p className="copyrightText">© Laphinh Sonexaythiketh 2019. All Rights reserved</p>
        </div>
      </footer> );
    }
}
 
export default Footer;