import React from 'react'

class Intro  extends React.Component {
    state = {  }
    render() { 
        return (
        <section className="container menuBottomCon grid-wrap"> 
        <div className="row main">
            <div className="lapin-son">
        </div>
            <div className="topContent">
            <h2 className="font-openBold">
            <span style={{fontSize: '22px', display: 'block',  lineHeight: '26px'}}>I am a passionate </span>
            <span style={{display: 'inline-block',fontWeight: "600", fontSize: '60px', fontVariant:'small-caps', color: '#000', minWidth: "362px"}}> ui designer <br />  front-end developer </span> 
            <span style={{fontSize: '22px', display: 'block',  lineHeight: '26px'}}>Freelance Developer based in Minnesota</span>

           </h2>
            </div>
        </div>
        </section> );
    }
}
 
export default Intro;