import React from 'react'


function SkillSetItem(props) {


    return(
        <React.Fragment>
        <div >
            <div className="mb-4">
                <p className="mb-2">{props.skillTitle} <span className="float-right text-muted">{props.skillValue}/10</span></p>
            <div className="progress">
                <div style={{ width: props.skillProgress }}  className="progress-bar" role="progressbar" aria-valuenow="66.66666666666666" aria-valuemin={0} aria-valuemax={100}  /></div>
            </div>
        </div>
        </React.Fragment>
    ) 
}   
    

export default SkillSetItem;