import React, { Component } from 'react';
import '../style.css';
import Footer from './Footer';
import Header from './Header'
import Main from './Main';

class Home extends React.Component {
  render() {
    return (
        <React.Fragment>
            <Header /> 
            <Main />
            <Footer />
        </React.Fragment>
    )
  }
}

export default Home;
