import React from "react"
import logo from '../img/logo.svg'

class Header extends React.Component {
    state = {  }
    render() { 
        return ( 
            <React.Fragment> 
            <header className="navbar navbar-default topNav">        
                <div className="container">
                <div className="navbar-header">
                {/* <button className="navbar-toggle" type="button" data-toggle="collapse" data-target=".bs-navbar-collapse">
                <span className="sr-only">Toggle navigation</span> <span className="icon-bar" /> <span className="icon-bar" /> <span className="icon-bar" /> </button> */}
                <a href="/" className="navbar-brand">
                
                <img src={logo} alt="logo" className="logo" /><span>Laphinh Sonxaythiketh</span></a> 
                
                <a class="LI-simple-link" target='_blank' href='https://www.linkedin.com/in/laphinh-sonexaythiketh-30883926?trk=profile-badge'><span className="LI-icons"><i className="fab fa-linkedin-in" /></span>
</a>  

                </div>

                {/* <div class="LI-profile-badge"  data-version="v1" data-size="medium" data-locale="en_US" data-type="vertical" data-theme="dark" data-vanity="laphinh-sonexaythiketh-30883926"><a class="LI-simple-link" href='https://www.linkedin.com/in/laphinh-sonexaythiketh-30883926?trk=profile-badge'>Laphinh Sonexaythiketh</a></div> */}
                {/* <nav role="navigation">
                <ul className="nav navbar-nav navbar-right">
                    <li><a href="index.html" className="resume-btn">Resume</a></li>
                    <li><a href="contact.html">Get in Touch</a></li>
                </ul>
                </nav> */}
                </div>
            </header>
            <hr />
            </React.Fragment>
        );
    }
}
 
export default Header;