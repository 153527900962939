import React from 'react';
import RecentWorkItem from './RecentWorkItem'
import ModalItem from "./ModalItem";

import Masonry from 'react-masonry-component';
import projectsData from '../data/projects.json';
import p1 from '../img/projects/p1.png'
import p2 from '../img/projects/p2.jpg'
import p3 from '../img/projects/p3.png'
import p4 from '../img/projects/p4.jpg'
import p5 from '../img/projects/p5.jpg'
import p6 from '../img/projects/p6.jpg'
import p7 from '../img/projects/p7.jpg'
import p8 from '../img/projects/p8.jpg'
import p9 from '../img/projects/p9.jpg'
import p10 from '../img/projects/p10.png'


const projectItem = projectsData.data;

        const data0 = {
            label: "CHS Inc.",
            detail: "CHS Inc. | Sitecore Page Refresh | 03.01.2019 - Current",
            url: "google.com",
            categorydetail: "Sitecore CMS, Front-End Development & UI/UX Designs",
            datedetail: " 03.01.2019 - Current",
            clientetail: "CHS Inc.",
            projectdetail: "Coming Soon",
            key: 3

        }
        const data1 = {
            label: "MN Board on Aging",
            detail: "MN Board on Aging | Refresh & Redesign Extranet | 01.01.2014 - 01.01.2019",
            url: "google.com",
            categorydetail: "Sitecore CMS, Front-End Development & UI/UX Designs",
            datedetail: "01.01.2014 - 01.01.2019",
            clientetail: " Minnesota Board on Aging",
            projectdetail: "Improve the overall look of MNagingproviders.org and bringing it up-to-par withmodern sites. Reimagine and reorganize the content into a more user-friendly site structure. Re-write all pages into HTML5/CSS3 and JQuery pages, and assets that will be refactored from existing Sitecore web pages. Assist back-end sitecore engineer with Sitcore integration from v6 to v8.",
            key: 3

        }
    const data2 = {
            label: "Great Clips Inc.",
            detail: "Styleware | POS for Great Clips Salons | 01.08.2012 - 01.02.2017",
            url: "google.com",
            categorydetail: "User Interface & Concept Designs",
            datedetail: " 01.08.2012 - 01.02.2017",
            clientetail: "Great Clips Inc.",
            projectdetail: "Details Coming Soon",
            key: 3
        }
    const data3 = {
            label: "MN Board on Aging",
            detail: "Response Form | Ticketing System Application | 01.01.2014 - 01.01.2019",
            url: "google.com",
            categorydetail: "Sitecore CMS, Front-End Development & UI/UX Designs",
            datedetail: "01.01.2014 - 01.01.2019",
            clientetail: " Minnesota Board on Aging",
            projectdetail: "Details Coming Soon",
            key: 3
        }
    const data4 = {
            label: "Target.com",
            detail: "The Target Refresh Project | Update pages for Target.com | 01.08.2011 - 01.02.2012",
            url: "google.com",
            categorydetail: "User Interface & Concept Designs",
            datedetail: "01.08.2011 - 01.02.2012",
            clientetail: "Olson Advertising ",
            projectdetail: "Details Coming Soon",
            key: 3
        }
        const data5 = {
            label: "MN Board on Aging",
            detail: "MN Board on Aging | Concepts for .com | 01.01.2014 - 01.01.2019",
            url: "google.com",
            categorydetail: "User Interface & Concept Designs",
            datedetail: "01.01.2014 - 01.01.2019",
            clientetail: "Minnesota Board on Aging",
            projectdetail: "Details Coming Soon",
            key: 3
        }
        const data6 = {
            label: "Great Clips Inc.",
            detail: "Quality Service Review | Franchise Management Software | 01.08.2012 - 01.02.2017",
            url: "google.com",
            categorydetail: "Front-End Development & UI/UX Designs",
            datedetail: "01.08.2012 - 01.02.2017",
            clientetail: "Great Clips Inc.",
            projectdetail: "Details Coming Soon",
            key: 3
        }
        const data7 = {
            label: "Great Clips Inc.",
            detail: "Stylists Employment Application | 01.08.2012 - 01.02.2017",
            url: "google.com",
            categorydetail: "Sitecore CMS, Front-End Development & UI/UX Designs",
            datedetail: "01.08.2011 - 01.02.2012",
            clientetail: "Great Clips Inc.",
            projectdetail: "Details Coming Soon",
            key: 3
        }
        const data8 = {
            label: "Great Clips Inc.",
            detail: "Real estate mapping tool | mapping tools for potential salons | 01.08.2012 - 01.02.2017",
            url: "google.com",
            categorydetail: "Front-End Development & UI/UX Designs",
            datedetail: " 01.08.2012 - 01.02.2017",
            clientetail: "Great Clips Inc.",
            projectdetail: "Details Coming Soon",
            key: 3
        }
        const data9 = {
            label: "MN Board on Aging",
            detail: "Minnesota help | Concepts for minnesotahelp.info | 01.01.2014 - 01.01.2019",
            url: "google.com",
            categorydetail: "User Interface & Concept Designs",
            datedetail: "01.01.2014 - 01.01.2019",
            clientetail: " Minnesota Board on Aging",
            projectdetail: "Details Coming Soon",
            key: 3
        }
        const data10 = {
            label: "Personal Concepts",
            detail: "Lapin Creative Co  |  01.01.2019",
            url: "google.com",
            categorydetail: "Front-End Development & UI/UX Designs",
            datedetail: "01.01.2019 - Current",
            clientetail: "S-Corp",
            projectdetail: "Details Coming Soon",
            key: 3
        }

    
class RecentWork extends React.Component {
    // constructor() {
    //     super()
    //     this.state = {
    //         label: "Very original idea!",
    //         detail: "Lenna Majeed | Tea Loaf with Fresh Oranges | 17.06.2018 - 09:20",
    //         url: "google.com",
    //         key: 3
    //         }
    // }
    render() {
    //  const projects =  projectItem.map(function(item, index) {
    //         return (
    //         <RecentWorkItem path={item.url} label={item.label} detail={item.detail} key={item.key} />
    //         )           
    //     })
        //
        return (
            <div className="container bodyContent grid-wrap">
            <hr />
                <ul className="portfolioList grid">
                <Masonry className="masonry" >
                {/* {data.map( => <ModalItem link={data1.key} label={data1.label} detail={data1.detail} img={p1}  />   )} */}
                    <ModalItem {...data1} img={p1}  /> 
                    <ModalItem {...data2} img={p2}  /> 
                    <ModalItem {...data3}  img={p3} /> 
                    <ModalItem {...data4}  img={p4} /> 
                    <ModalItem {...data5}  img={p5} /> 
                    <ModalItem {...data6}  img={p6} /> 
                    <ModalItem {...data7}  img={p7} /> 
                    <ModalItem {...data8}  img={p8} /> 
                    <ModalItem {...data9} img={p9} /> 
                    <ModalItem {...data10} img={p10} /> 
                </Masonry>
                </ul>
            </div>
        )
        
    }
}

export default RecentWork;