import React from 'react'
import Intro from './Intro'
import SkillSets from './SkillSets'
import ModalItem from './ModalItem'
import RecentWork from './RecentWork'


class Main extends React.Component {
    state = {  }
    render() { 
        return ( 
            <main>
            <Intro />
            {/* <hr /> */}
            {/* <SkillSets /> */}           
            <RecentWork />
          
            </main>
         );
    }
}
 
export default Main;