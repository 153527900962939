
import React from "react"
import Footer from './Footer';
import Header from './Header'

class Error404 extends React.Component {
    state = {  }
    render() { 
        return (
        <React.Fragment>
            <Header /> 
            <section className="container img404">
                <p className="chain_broken"><i className="fa fa-chain-broken fa-6" /></p>
                <h3 className="txt404">sorry the page you are looking for does not exist.</h3>
                <p className="txt404Sub">You can explore our site back to the navigation below.</p>
                <div className="separator"><span className="sepIcon in404" /></div>
            </section>
            <Footer />
        </React.Fragment>
        );
    }
}
 
export default Error404;