import React from 'react'
import SkillSetItem from './SkillSetItem'
import frontSkills from  '../data/frontSkills'
import backSkills from  '../data/backSkills'
import { SlideToggle } from 'react-slide-toggle'


const frontSkillsItem = frontSkills.data;
const backSkillsItem = backSkills.data;



class SkillSets extends React.Component {
    
  constructor() {
    super()
    this.state = {
      frontSkillsItem:{}   
    }  
}
    render() { 
     const frontEndSkills = frontSkillsItem.map(data => <SkillSetItem skillTitle={data.label} skillValue={data.skill} skillProgress={data.progress} />);
     const backEndSkills = backSkillsItem.map(data => <SkillSetItem skillTitle={data.label} skillValue={data.skill} skillProgress={data.progress} />);

        return ( 
          <SlideToggle 
          collapsed 
          onCollapsing={({range, progress, hasReversed}) => { /* optional event hook */ }}
          render={({onToggle, setCollapsibleElement,progress }) => (
            <React.Fragment>
            <div className="slide-toggle" >
          
      <div className="slide-toggle__header" style={{textAlign: 'center'}}>
      <a href="#" className="btn btn-sm animated-button victoria-four" onClick={onToggle}> Skill Sets</a> 
      </div>
      <div className="slide-toggle__box container grid-wrap collapsed" ref={setCollapsibleElement}>
        <div className="slide-toggle__box-inner" style={{width: '70%', opacity: Math.max(.5, progress) }}>
          {frontEndSkills}
        </div>
        <div className="slide-toggle__box-inner" style={{width: '70%',opacity: Math.max(.5, progress) }}>
          {backEndSkills}
        </div>
      </div>
    </div>
 
                </React.Fragment>
                )}
                />
    
         );
    }
}
 
export default SkillSets;