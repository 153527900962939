import React from 'react'
import p1 from '../img/projects/p1.png'
import ModalItem from "./ModalItem";


function RecentWorkItem(props) {
console.log(props.key)
        const data1 = {
            label: "MN Board on Aging",
            detail: "MN Board on Aging | Refresh & Redesign Extranet | 01.01.2014 - 01.01.2019",
            url: "google.com",
            key: 3
        }
    return(
         <React.Fragment>
        
        <li>          
            <div className="listLink">
                <div className="portfolioSlide">
                    <img src={props.img} />
                </div>     
                <div className="portfolioContent">
                    <h3 className="font-openBold"><a href={"/ProjectDetail/" + props.link}></a>
                     {props.label} {props.key}<span>{props.detail}</span></h3>
                </div>
               
            </div>
        </li>
        </React.Fragment> 
    )
}
 
export default RecentWorkItem;