import React from "react";
import { render } from "react-dom";
import Modal from "react-responsive-modal";

const styles = {
  fontFamily: "sans-serif",
  textAlign: "center"
};

class ModalItem extends React.Component {
  state = {
    open: false
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;
    return (
        <React.Fragment>
        <li>          
            <div className="listLink">
                <div className="portfolioSlide">
                    <img src={this.props.img} />
                </div>     
                <div className="portfolioContent">
                    <h3 className="font-openBold"><a href="#"  onClick={this.onOpenModal}></a>
                     {this.props.label} {this.props.key}<span>{this.props.detail}</span></h3>
                </div>
               
            </div>
        
      <div style={styles}>
        <Modal open={open} onClose={this.onCloseModal} center>
        <div className="container bodyContent" >
                <h3 className="page-title"></h3>
                <div className="row">         
                    <div className="col-lg-7">
                    <article className="project clearfix">   
                        <div className="img-responsive postImage" style={{ }}>
                        <img src="http://placehold.it/630x300/fca503/ffffff&amp;text=Coming Soon" class="img-responsive postImage" alt="" />
                        </div>  
                        <div className="img-responsive postImage" style={{ maxWidth: '630px' }}>
                        <img src="http://placehold.it/630x300/14b9eb/ffffff&amp;text=Coming Soon" class="img-responsive postImage" alt="" />
                        </div>  
                        <div className="img-responsive postImage" style={{ maxWidth: '630px' }}>
                        <img src="http://placehold.it/630x300/fe6cd5/ffffff&amp;text=Coming Soon" class="img-responsive postImage" alt="" />
                        </div>
                        <div className="img-responsive postImage" style={{ maxWidth: '630px' }}>
                        <img src="http://placehold.it/630x300/1be3c6/ffffff&amp;text=Coming Soon" class="img-responsive postImage" alt="" />
                        </div>   
                    </article>
                    </div>
                    <div className="">
                    <aside className="widget">
                        <h3 className="sidebarprojecttitle">Project Category</h3>
                        <p className="sidebarEntry">
                          {this.props.categorydetail}
                        </p>
                        <h3 className="sidebarprojecttitle">Date</h3>
                        <p className="sidebarEntry">
                          {this.props.datedetail}
                        </p>
                        <h3 className="sidebarprojecttitle">Client name</h3>
                        <p className="sidebarEntry">
                          {this.props.clientetail}
                        </p>
                        <h3 className="sidebarprojecttitle">Project Details</h3>
                        <p className="sidebarEntry">
                          {this.props.projectdetail}
                        </p>
                        <p className="sidebarEntry"><a href="#"></a></p>
                    </aside>
                    </div>
                </div>
             </div>
        </Modal>
      </div>
      </li>
      </React.Fragment>
    );
  }
}
export default ModalItem;