
import React from "react"
import ReactCompareImage from 'react-compare-image';
import Header from './Header'
import Footer from './Footer'
import projectDetail from '../data/projectDetail'
import macBook from '../img/LaphinhSMacBook.svg'
import Lightbox from 'react-images';

import pic1A from '../img/projects/project_1/1A.png'
import pic1B from '../img/projects/project_1/1B.png'
import pic2A from '../img/projects/project_1/2A.png'
import pic2B from '../img/projects/project_1/2B.png'
import pic3A from '../img/projects/project_1/3A.png'
import pic3B from '../img/projects/project_1/3B.png'

import con1 from '../img/projects/concepts_1/c1.png'
import con2 from '../img/projects/concepts_1/c2.png'
import con3 from '../img/projects/concepts_1/c3.png'

// const projectId = window.location.pathname.split('/ProjectDetail/')[1];


const projectData = projectDetail.data

class ProjectDetail extends React.Component {
  
    render() { 
        // const projectData1 = JSON.parse(projectData)

        console.log();

        return (
            <React.Fragment>
            <Header />
            <div className="container bodyContent" style={{width:'1150px'}}>
                <h3 className="page-title"></h3>
                <div className="row">
          
                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8 blogleft">
                    <article className="project clearfix">   
                  
                        {/* <div className="img-responsive lapin-mac-book" style={{ maxWidth: '1366px ' }}>    */}

                        {/* <Lightbox
                        images={[
                            { src: {con1} },
                            { src: {con2} }
                        ]}
                            isOpen={this.state.lightboxIsOpen}
                            onClickPrev={this.gotoPrevLightboxImage}
                            onClickNext={this.gotoNextLightboxImage}
                            onClose={this.closeLightbox}
                        /> */}

                        {/* <ReactCompareImage                     
                            leftImage={pic1A}
                            rightImage={pic1B}
                            skeleton={<div>loading</div>}
                        />
                           </div>      */} 

                        <div className="img-responsive postImage" style={{ }}>
                        <img src={con1} />
                        </div>  
                        <div className="img-responsive postImage" style={{ maxWidth: '630px' }}>
                        <img src={con2} />
                        </div>  
                        <div className="img-responsive postImage" style={{ maxWidth: '630px' }}>
                        <img src={con3} />
                        </div>  

                    </article>
                    <div className="postnav clearfix">
                        <a href="#" className="post-prev">Older Post</a>
                        <a href="#" className="post-next">Previous Post</a>
                    </div>
                    </div>
                    <div className="">
                    <aside className="widget">
                        <h3 className="sidebarprojecttitle">Project Category</h3>
                        <p className="sidebarEntry">
                        UI/UX Designs
                        </p>
                        <h3 className="sidebarprojecttitle">Date</h3>
                        <p className="sidebarEntry">
                            00.00.000 - 31.12.2018
                        </p>
                        <h3 className="sidebarprojecttitle">Client name</h3>
                        <p className="sidebarEntry">
                            Minnesota Board on Aging
                        </p>
                        <h3 className="sidebarprojecttitle">Project Details</h3>
                        <p className="sidebarEntry">
                            Improve the overall look of MNagingproviders.org and bringing it up-to-par with
                            modern sites. Reimagine and reorganize the content into a more user-friendly site structure. Re-write all 16
                            types of pages into HTML5/CSS3 and JQuery pages, and their assets that will be refactored from existing
                            Sitecore web pages. Assist back-end sitecore engineer with Sitcore integration from v6 to v8.
                        </p>
                        <p className="sidebarEntry"><a href="#"></a></p>
                    </aside>
                    </div>
                </div>
             </div>
             <Footer />
             </React.Fragment>
        );
    }
}
 
export default ProjectDetail;